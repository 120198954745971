import { IGatewayClient } from './IGatewayClient';
import { mapToGatewayRequest } from './gatewayMapper';
import { IFormData } from '../model/IFormData';
import { IPromotion } from './IPromotion';
import { IGetPromotionsFetchServiceResponse } from './IPromotionFetchServiceResponse';
import { INationality } from '../model/INationality';
import { IPersonGatewayInfo } from '../model/IPersonGatewayInfo';
import { IGatewayPerson } from './model/IGatewayPerson';
import { IGatewaySavePersonsResponse } from './model/IGatewaySavePersonsResponse';
import { AxiosInstance } from 'axios';

export const createClient = (fetchService: AxiosInstance, baseGatewayUrl: string): IGatewayClient => {
    return {
        createPersons: async (businessId: string, amountOfPersons: number): Promise<IGatewayPerson[]> => {
            const response = await fetchService.post(`${baseGatewayUrl}/createPersonIds`, {
                businessId,
                amountOfPersons
            });
            return response.data.persons;
        },

        savePersons: async (businessId, values: IFormData, personsGatewayInfo: IPersonGatewayInfo[], validateAddress: boolean)
            : Promise<IGatewaySavePersonsResponse> => {
            const payload = mapToGatewayRequest(businessId, values, personsGatewayInfo, validateAddress);
            const response = await fetchService.put<IGatewaySavePersonsResponse>(`${baseGatewayUrl}/savePersons`, payload);
            return response.data;
        },

        isPromotionAvailable: async (tariff: string, aktionsnummer: string): Promise<boolean> => {
            let response: IGetPromotionsFetchServiceResponse;
            try {
                response = await fetchService.get(`${baseGatewayUrl}/promotions/${tariff}/${aktionsnummer}`);
            } catch (e) {
                return false;
            }
            return response.status === 200 && (response.data as Array<IPromotion>).length > 0;
        },

        isPromotionSuccessful: async (tariff: string, aktionsnummer: string, promotionCode: string): Promise<boolean> => {
            let response: IGetPromotionsFetchServiceResponse;
            try {
                response = await fetchService.get(`${baseGatewayUrl}/promotions/${tariff}/${aktionsnummer}/${promotionCode}`);
            } catch (e) {
                return false;
            }
            return response.status === 200;
        },

        getNationalities: async (): Promise<Array<INationality>> => {
            const response = await fetchService.get(`${baseGatewayUrl}/nationalities`);
            return response.data.sort((n1: INationality, n2: INationality) => n1.label.localeCompare(n2.label));
        },

        getIbanValidity: async(iban, bic): Promise<boolean> => {
            const optionalBicParameter = bic ? `?bic=${bic}` : '';

            try {
                const response = await fetchService.get(`${baseGatewayUrl}/ibans/${iban}${optionalBicParameter}`);
                return response.data.isIbanValid;
            } catch {
                // if the service is not working let the user go on...
                return true;
            }
        }
    };
};

