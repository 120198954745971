import { IConfig } from './IConfig';

// eslint-disable-next-line
const baseUrl = '';

const prodConfig: IConfig = {
    baseGatewayUrl: baseUrl,
    loadCss: (theme, baseGatewayUrl) => {
        const link = document.createElement('link');
        const href = `${baseGatewayUrl}/static/css/${theme}.css`;
        link.href = href;
        link.rel = 'stylesheet';
        document.head.appendChild(link);

        const resolverFx = (resolve: (value?: void | PromiseLike<void>) => void) => {
            // set up an interval that checks if the stylesheet has already been loaded to avoid FOUC
            const interval = setInterval(() => {
                const length = document.styleSheets.length;
                for (let i = 0; i < length; i++) {
                    const css = document.styleSheets[i];
                    if (css.href === href) {
                        resolve();
                        clearInterval(interval);
                        break;
                    }
                }
            },                           250);
        };

        return new Promise(resolverFx);
    }
};

export default prodConfig;
