import { IGatewaySavePersonsResponse } from '../infrastructure/model/IGatewaySavePersonsResponse';
import InquiryType from '../messaging/model/input/InquiryType';
import { IApiError } from '../model/IApiError';
import { IFormData } from '../model/IFormData';
import { INationality } from '../model/INationality';
import { IPersonGatewayInfo } from '../model/IPersonGatewayInfo';

export interface IAppState {
    inquiryType?: InquiryType;
    personsGatewayInfo: IPersonGatewayInfo[];
    nationalities: INationality[];
    
    isValidStreetVn?: boolean;
    isValidHousenumberVn?: boolean;
    isValidPostalCityVn?: boolean;

    isValidStreetVp?: boolean;
    isValidHousenumberVp?: boolean;
    isValidPostalCityVp?: boolean;

    nextClickedValidateAddress?: boolean;
    isIbanValid: boolean;
    apiError: IApiError;

    validationResults?: IGatewaySavePersonsResponse;
    formdata? : IFormData;
}



export const createInitialState = (personsGatewayInfo: IPersonGatewayInfo[]): IAppState => {
    return {
        personsGatewayInfo: personsGatewayInfo,
        nationalities: [],
        nextClickedValidateAddress: false,
        isIbanValid: false,
        apiError: {},
        isValidPostalCityVn: true, isValidStreetVn: true, isValidHousenumberVn: true,
        isValidPostalCityVp: true, isValidStreetVp: true, isValidHousenumberVp: true, validationResults: {persons:[]}, formdata: {vn: {
            email: '',
            emailEwe: '',
            vorwahl: '',
            rufnummer: '',
            iban: '',
            bic: '',
            abbuchungsTage: [],
            vorname: '',
            nachname: '',
            geburtsort: '',
            strasse: '',
            hausnummer: '',
            plz: '',
            ort: '',
            geburtsdatum: {}
        }, vps:[]}
    };
};
