import { IAppInput } from './IAppInput';
import { IFormData } from '../../../model/IFormData';
import { IInputVn } from './IInputVn';
import { IFormPolicyHolder } from '../../../model/IFormPolicyHolder';
import { IAdresse } from '../IAdresse';
import { IInputVp } from './IInputVp';
import { IFormInsuredPerson } from '../../../model/IFormInsuredPerson';
import { IDriverLicense } from '../../../model/IDriverLicense';
import { IFuehrerscheinInput } from './IFuehrerscheinInput';
import { DateValidation } from '../../../model/DateValidation';
import { IStaticDriverLicense, IStaticPersonalData, IStaticPolicyHolder, IStaticInsuredPerson } from '../../../model/IStaticPersonalData';
import { mapDateToDateInputValue, mapDateToGermanString } from '../../../common/dateMapper';
import { IStaticAppValues } from '../../../model/IStaticAppValues';
import { THEME } from '@eg/elements/components/Provider';
import { IPersonGatewayInfo } from '../../../model/IPersonGatewayInfo';

const mapInputToAdresse = (source: IAdresse | undefined, hasDifferentAddress: boolean): IAdresse => {
    // check for hasDifferentAddress: VP addresses that equal the VN address are internally 'empty'
    if (source !== undefined && hasDifferentAddress) {
        return {
            strasse: source.strasse || '',
            hausnummer: source.hausnummer || '',
            plz: source.plz || '',
            ort: source.ort || '',
            id: source.id || undefined
        };
    }
    return {
        strasse: '',
        hausnummer: '',
        plz: '',
        ort: '',
        id: undefined
    };
};

const mapToFuehrerschein = (source: IFuehrerscheinInput | undefined): { formData: IDriverLicense, staticValues: IStaticDriverLicense }|undefined => {
    if (!source) {
        return undefined;
    }
    return {
        formData: {
            datum: source.datum ? mapDateToDateInputValue(source.datum) : {
                    year: '',
                    month: '',
                    day: ''
                }
        },
        staticValues: {
            datumValidation: source.datumValidation as DateValidation,
            isLicenseDateReadonly: source.isLicenseDateReadonly || false
        }
    };
};

// tslint:disable-next-line:no-any
export const isValueAvailableInDropdown = (inputArray: any[], inputValue: any) => {
    return inputArray.indexOf(inputValue) > -1;
};

const generateDefaultVnHeadline = (vpsCount: number) =>
    `Persönliche Daten des Versicherungsnehmers${vpsCount > 0 ? '' : ' und der zu versichernden Person'}`;

const guard = ({ vn, vps, angebotsId }: IAppInput) => {
    if (!vn.geburtsdatum) {
        throw new Error('vn.geburtsdatum is mandatory!');
    }

    if (vn.isSexReadOnly && (typeof vn.isSexReadOnly !== 'boolean')) {
        throw new Error('vn.isSexReadOnly is not a boolean!');
    }

    if (vn.abbuchungsTage !== undefined && vn.tagDerAbbuchung !== undefined && !isValueAvailableInDropdown(vn.abbuchungsTage, vn.tagDerAbbuchung)) {
        throw new Error('Chosen Abbuchungsdatum is not an available options in AbbuchungsTageField');
    }

    if (vps !== undefined && vps.filter(vp => vp.isCityAndPostCodeReadonly).length > 1) {
        throw new Error('isCityAndPostCodeReadonly may only be specified for zero or one VPs');
    }
};

const mapToPolicyHolder = (vn: IInputVn, vpsCount: number): { formData: IFormPolicyHolder, staticValues: IStaticPolicyHolder } => {
    const {email, einverstaendnisEmailWerbung} = vn;

    const tagDerAbbuchung = vn.tagDerAbbuchung || ((vn.abbuchungsTage && vn.abbuchungsTage.length > 0) ? vn.abbuchungsTage[0] : undefined);

    const fuehrerschein = mapToFuehrerschein(vn.fuehrerschein);
    const formData: IFormPolicyHolder = {
        geschlecht: vn.geschlecht,
        titel: vn.titel,
        vorname: vn.vorname || '',
        nachname: vn.nachname || '',
        geburtsdatum: mapDateToDateInputValue(vn.geburtsdatum),
        ...mapInputToAdresse(vn.adresse, true),
        geburtsort: vn.geburtsort || '',
        staatsangehoerigkeit: vn.staatsangehoerigkeit,
        vorwahl: vn.vorwahl || '',
        rufnummer: vn.rufnummer || '',
        emailEwe: einverstaendnisEmailWerbung ? email || '' : '',
        email: !einverstaendnisEmailWerbung ? email  || '' : '',
        iban: vn.iban || '',
        bic: vn.bic || '',
        abbuchungsTage: vn.abbuchungsTage || undefined,
        tagDerAbbuchung,
        fuehrerschein: fuehrerschein ? fuehrerschein.formData : undefined,
        gwgoptions: vn.isGWGRequired ? {
            gwgRequired: vn.isGWGRequired,
            wirtschaftlicheigentverantwortlich: vn.wirtschaftlicheigentverantwortlich
        } : undefined,
    };

    const staticValues: IStaticPolicyHolder = {
        ueberschrift: vn.ueberschrift || generateDefaultVnHeadline(vpsCount),
        fuehrerschein: fuehrerschein ? fuehrerschein.staticValues : undefined,
        abbuchungsTage: vn.abbuchungsTage || [],
        tagesdatum: mapDateToGermanString(new Date()),
        isGWGRequired: vn.isGWGRequired || false,
        showStaatsangehoerigkeit: vn.showStaatsangehoerigkeit || false,
        showGeburtsort: vn.showGeburtsort || false,
        isSexReadOnly: vn.isSexReadOnly || false,
        hasInternationalIbanSupport: vn.hasInternationalIbanSupport || false,
        hasEuropeanIbanSupport: vn.hasEuropeanIbanSupport || false,
        isEmailOptional: vn.isEMailOptional || false,
        showEmailConfirmationTooltip: vn.showEmailConfirmationTooltip || false,
        showSepaBankInfo: !(vn.showSepaBankInfo === false)
    };

    return {
        formData,
        staticValues
    };
};

const generateDefaultVpHeadline = (vpCount: number, index: number): string => {
    return `Persönliche Daten der ${vpCount > 1 ? `${index + 1}. ` : ''}zu versichernden Person`;
};

const mapToInsuredPersons = (source: IInputVp[] | undefined, policyHolder: IFormPolicyHolder): { formData: IFormInsuredPerson[], staticValues: IStaticInsuredPerson[] }  => {
    if (!source) {
        return {
            formData: [],
            staticValues: []
        };
    }

    const policyHolderAddress = {
        strasse: policyHolder.strasse,
        hausnummer: policyHolder.hausnummer,
        plz: policyHolder.plz,
        ort: policyHolder.ort,
    };

    const insuredPersons = source.map((insuredPerson: IInputVp, index: number): { formData: IFormInsuredPerson, staticValues: IStaticInsuredPerson } => {
        const hasDifferentAddress = insuredPerson.adresse !== undefined &&
            (policyHolderAddress.strasse !== insuredPerson.adresse.strasse
                || policyHolderAddress.hausnummer !== insuredPerson.adresse.hausnummer
                || policyHolderAddress.plz !== insuredPerson.adresse.plz
                || policyHolderAddress.ort !== insuredPerson.adresse.ort);

        const insuredPersonAddresses = mapInputToAdresse(insuredPerson.adresse as IAdresse, hasDifferentAddress);

        return {
            formData: {
                geschlecht: insuredPerson.geschlecht,
                titel: insuredPerson.titel,
                vorname: insuredPerson.vorname || '',
                nachname: insuredPerson.nachname || '',
                geburtsdatum: insuredPerson.geburtsdatum
                    ? mapDateToDateInputValue(insuredPerson.geburtsdatum)
                    : {
                        year: '',
                        month: '',
                        day: ''
                    },
                ...insuredPersonAddresses,
                hasDifferentAddress,
                geburtsort: insuredPerson.geburtsort || '',
                staatsangehoerigkeit: insuredPerson.staatsangehoerigkeit,
                gwgoptions: insuredPerson.isGWGRequired ? {
                    gwgRequired: insuredPerson.isGWGRequired,
                    wirtschaftlicheigentverantwortlich: true
                }
                : undefined,
            },
            staticValues: {
                ueberschrift: insuredPerson.ueberschrift || generateDefaultVpHeadline(source.length, index),
                isWohnortReadOnly: insuredPerson.isCityAndPostCodeReadonly || false,
                geburtsdatumValidation: insuredPerson.geburtsdatumValidation as DateValidation,
                isGeburtsdatumWriteable: insuredPerson.isGeburtsdatumWriteable || false,
                isGWGRequired: !!insuredPerson.isGWGRequired,
                showStaatsangehoerigkeit: !!insuredPerson.showStaatsangehoerigkeit,
                showGeburtsort: !!insuredPerson.showGeburtsort,
                isSexReadOnly: insuredPerson.isSexReadOnly || false
            }
        };
    });

    return {
        formData: insuredPersons.map(vp => vp.formData),
        staticValues: insuredPersons.map(vp => vp.staticValues)
    };
};

const mapToAppModel = (source: IAppInput): {
    formData: IFormData,
    staticPersonalData: IStaticPersonalData,
    staticAppValues: IStaticAppValues,
    personsGatewayInfo: IPersonGatewayInfo[]
} => {
    guard(source);

    const policyHolder = mapToPolicyHolder(source.vn, source.vps ? source.vps.length : 0);
    const insuredPersons = mapToInsuredPersons(source.vps, policyHolder.formData);

    return {
        formData: {
            vn: policyHolder.formData,
            vps: insuredPersons.formData
        },
        staticPersonalData: {
            vn: policyHolder.staticValues,
            vps: insuredPersons.staticValues,
            disableEwe: Boolean(source.disableEwe),
            angebotsId: source.angebotsId,
            showTitel: source.showTitel === true
        },
        staticAppValues: {
            repository: source.repository,
            theme: source.theme || THEME.ergodirekt,
            text: {
                creditor: source.creditor.name, // 'ERGO Direkt',
                creditorIdentNumber: source.creditor.identNumber // : 'DE25EDL00000041714'
            },
            promotion: source.promotion,
        },
        personsGatewayInfo: [source.vn, ...(source.vps || [])]
            .map(person => {
                return {
                    role: person.role,
                    personId: person.id,
                    addressId: person.adresse?.id
                };
            })
    };
};

export { mapToAppModel };
