import * as React from "react";
import { connect } from "react-redux";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { ApiErrorContext } from "./App";
import { createCleanAddress } from "./cleanAdress";
import { IGatewayClient } from "./infrastructure/IGatewayClient";
import { IGatewaySavePersonsResponse } from "./infrastructure/model/IGatewaySavePersonsResponse";
import { IMessaging } from "./messaging/messaging";
import { ICallbacks } from "./messaging/model/input/ICallbacks";
import InquiryType from "./messaging/model/input/InquiryType";
import { IApiError } from "./model/IApiError";
import { IFormData } from "./model/IFormData";
import { IFormInsuredPerson } from "./model/IFormInsuredPerson";
import { IFormPolicyHolder } from "./model/IFormPolicyHolder";
import { INationality } from "./model/INationality";
import { IPersonGatewayInfo } from "./model/IPersonGatewayInfo";
import { ITextStatic } from "./model/IStaticAppValues";
import { IStaticPersonalData } from "./model/IStaticPersonalData";
import BankdatenSection from "./sections/BankdatenSection";
import BezugsrechtHinweisSection from "./sections/BezugsrechtHinweisSection";
import GwgInformationSection from "./sections/GwgInformationSection";
import AnnotationPortal from "./subcomponents/AnnotationPortal";
import OnetimeEmailField from "./subcomponents/OnetimeEmailField";
import Vn from "./subcomponents/Vn";
import { IVpProps } from "./subcomponents/Vp";
import Vps from "./subcomponents/Vps";
import { showBicAndRememberState } from "./utils/showBicAndRememberState";
import { isOneTimeEmailVisible } from "./utils/visibility";
import { getVpsValidationFlags } from "./validate";
import { asyncValidateIbanAndBic } from "./validateAsync";

const formName = "personendaten";

export interface IFormComponentProps {
  onEnterPress: () => void;
  inquiryType?: InquiryType;
  mappedValues: IMappedValues;
  staticPersonalData: IStaticPersonalData;
  staticText: ITextStatic;
  isBankdatenVisible: boolean;
  isVnCityReadonly: boolean;
  hinweisBezugsrecht: boolean;
  messaging: IMessaging;
  onNextClick: (values: IFormData, inquiryType: InquiryType) => void;
  nationalities: INationality[];
  validateAddress?: boolean;

  isValidStreetVn?: boolean;
  setValidStreetVn?: (valid: boolean) => void;
  isValidHousenumberVn?: boolean;
  setValidHousenumberVn?: (valid: boolean) => void;
  isValidPostalCityVn?: boolean;
  setValidPostalCityVn?: (valid: boolean) => void;

  callbacks: ICallbacks;
  onIbanValidated: (
    values: IFormData,
    isIbanValid: boolean,
    emitEvent: boolean
  ) => void;
  api: IGatewayClient;

  personsGatewayInfo?: IPersonGatewayInfo[];
  validationResults?: IGatewaySavePersonsResponse;
}



// see https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/redux-form/redux-form-tests.tsx#L82-L93
export type IMergedFormProps = IFormComponentProps &
  InjectedFormProps<IFormData, IFormComponentProps>;

// tslint:disable-next-line

class RawForm extends React.Component<IMergedFormProps> {
  private bicShown = false;

  setBicShown = (value: boolean) => {
    this.bicShown = value;
  };

  componentDidMount() {
    this.props.messaging.listenToNextClicked((e: CustomEvent) => {
      this.props.onNextClick(this.props.mappedValues, e.detail as InquiryType);
    });

    // The next line is important to initialize the IBAN and BIC error status
    // Especially if the IBAN / BIC has already been filled
    // Currently incorrectly maintained fields are not touched (the touch would also result in error messages displayed)
    if (this.props.isBankdatenVisible) {
      this.props.asyncValidate();
    }
  }

  componentDidUpdate(prevProps: Readonly<IMergedFormProps>): void {
    // siehe componentDidMount: initialisierung des Fehlerzustands von IBAN und BIC
    if (!prevProps.isBankdatenVisible && this.props.isBankdatenVisible) {
      this.props.asyncValidate();
    }
  }

  
  render() {
    // we augment the array we added inside mapStateToProps with functions that handle the cleaning of the address fields
    // has to be done here because change and untouch are not available within mapStateToProps

    const vps = this.props.mappedValues.vps.map(
      (vp: IVpWithMappings, index: number): IVpProps => {
        const vpsFlags = getVpsValidationFlags(index, this.props.personsGatewayInfo, this.props.validationResults);
        return {
          headline: this.props.staticPersonalData.vps[index].ueberschrift,
          hasDifferentAddress: vp.hasDifferentAddress,
          isWohnortReadOnly:
            this.props.staticPersonalData.vps[index].isWohnortReadOnly,
          isGeburtsdatumWriteable:
            this.props.staticPersonalData.vps[index].isGeburtsdatumWriteable,
          geburtsdatumValidation:
            this.props.staticPersonalData.vps[index].geburtsdatumValidation,
          cleanAddress: createCleanAddress(
            index,
            vp.hasDifferentAddress,
            vp.plz,
            vp.ort,
            this.props.staticPersonalData.vps[index].isWohnortReadOnly,
            this.props.change,
            this.props.untouch
          ),
          isGWGRequired: this.props.staticPersonalData.vps[index].isGWGRequired,
          showStaatsangehoerigkeit:
            this.props.staticPersonalData.vps[index].showStaatsangehoerigkeit,
          showGeburtsort:
            this.props.staticPersonalData.vps[index].showGeburtsort,
          touch: this.props.touch,
          nationalities: this.props.nationalities,
          showTitel: this.props.staticPersonalData.showTitel,
          isSexReadOnly: this.props.staticPersonalData.vps[index].isSexReadOnly,
          isValidStreetVp: vpsFlags.isValidStreetVp,
          isValidHousenumberVp: vpsFlags.isValidHousenumberVp,
          isValidPostalCityVp: vpsFlags.isValidPostalCityVp,
          role: 'vp',
          validateAddress: this.props.validateAddress
        };
      }
    );

    return (
      <ApiErrorContext.Consumer>
        {(context: IApiError) => (
          <>
            {context.message && (
              <p style={{ color: "red" }}>
                {context.message}
                {context.reason && ": " + context.reason}
              </p>
            )}
            <form
              onKeyDown={
                // tslint:disable-next-line:no-any
                (e: any) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    this.props.onEnterPress();
                  }
                }
              }
            >
              <Vn
                staticData={this.props.staticPersonalData.vn}
                dynamicData={this.props.mappedValues.vn}
                disableEwe={this.props.staticPersonalData.disableEwe}
                touch={this.props.touch}
                nationalities={this.props.nationalities}
                validateAddress={this.props.validateAddress}
                isValidStreetVn={this.props.isValidStreetVn}
                setValidStreetVn={this.props.setValidStreetVn}
                isValidHousenumberVn={this.props.isValidHousenumberVn}
                setValidHousenumberVn={this.props.setValidHousenumberVn}
                isValidPostalCityVn={this.props.isValidPostalCityVn}
                setValidPostalCityVn={this.props.setValidPostalCityVn}
                showTitel={this.props.staticPersonalData.showTitel}
              />
              <Vps vps={vps} />

              {this.props.staticPersonalData.vn.isGWGRequired && (
                <GwgInformationSection
                  touch={this.props.touch}
                  onGWGBeratungsdialogDismissed={
                    this.props.callbacks.onGWGBeratungsdialogDismissed
                  }
                  onGWGBeratungsdialogForward={
                    this.props.callbacks.onGWGBeratungsdialogForward
                  }
                />
              )}

              {this.props.isBankdatenVisible && (
                <BankdatenSection
                  fullName={this.props.mappedValues.vn.fullName}
                  datum={this.props.staticPersonalData.vn.tagesdatum}
                  abbuchungsTage={
                    this.props.staticPersonalData.vn.abbuchungsTage
                  }
                  text={this.props.staticText}
                  showBic={showBicAndRememberState(
                    !!this.props.staticPersonalData.vn
                      .hasInternationalIbanSupport,
                    this.bicShown,
                    this.setBicShown,
                    this.props.mappedValues.vn.iban,
                    this.props.staticPersonalData.vn.hasEuropeanIbanSupport
                  )}
                  showSepaBankInfo={
                    this.props.staticPersonalData.vn.showSepaBankInfo
                  }
                />
              )}

              {this.props.hinweisBezugsrecht && <BezugsrechtHinweisSection />}

              {this.props.mappedValues.isOneTimeEmailVisible && (
                <>
                  {this.props.isBankdatenVisible && (
                    <>
                      <br />
                      <hr />
                      <br />
                    </>
                  )}
                  <OnetimeEmailField
                    meta={{ scope: "vn.", role: "" }}
                    isOptional={
                      this.props.staticPersonalData.vn.isEmailOptional
                    }
                    showEmailConfirmationTooltip={
                      this.props.staticPersonalData.vn
                        .showEmailConfirmationTooltip
                    }
                  />
                </>
              )}
            </form>
            {!this.props.staticPersonalData.disableEwe && <AnnotationPortal />}
          </>
        )}
      </ApiErrorContext.Consumer>
    );
  }
}

const Form = reduxForm({
  form: formName,
  pure: false,
  touchOnChange: false,
  asyncChangeFields: ["vn.iban", "vn.bic"],
  asyncValidate: asyncValidateIbanAndBic,
})(RawForm as any);

export interface IVnWithMappings extends IFormPolicyHolder {
  isWohnortReadOnly: boolean;
  fullName: string;
}

interface IVpWithMappings extends IFormInsuredPerson {
  hasDifferentAddress: boolean;
}

interface IMappedValues {
  vn: IVnWithMappings;
  vps: IVpWithMappings[];
  isOneTimeEmailVisible: boolean;
}

// Decorate with connect to read form values, see https://redux-form.com/7.3.0/examples/selectingformvalues/
const selector = formValueSelector(formName);

const mapStateToProps = (
  state: IMergedFormProps,
  ownProps: IFormComponentProps
): Partial<IFormComponentProps> => {
  // we assemble an array which carries information that we need as additional props for each VP
  const vps: IVpWithMappings[] = [];

  const vnFromSelector = selector(state, "vn") as IFormPolicyHolder;
  const vpsFromSelector = selector(state, "vps") as Array<IFormInsuredPerson>;

  let isVnWohnortReadOnly = ownProps.isVnCityReadonly || false;

  if (vpsFromSelector) {
    vpsFromSelector.forEach((vpFromSelector: IVpWithMappings, i: number) => {
      // calculate if plz and ort of VN has to be readonly
      if (
        ownProps.staticPersonalData.vps[i].isWohnortReadOnly &&
        !vpFromSelector.hasDifferentAddress
      ) {
        isVnWohnortReadOnly = true;
      }

      const useVPWohnort =
        vpFromSelector.hasDifferentAddress || isVnWohnortReadOnly === false;

      vps.push({
        ...vpFromSelector,
        plz: useVPWohnort ? vpFromSelector.plz : vnFromSelector.plz,
        ort: useVPWohnort ? vpFromSelector.ort : vnFromSelector.ort,
      });
    });
  }

  const mappedValues: IMappedValues = {
    vn: {
      ...vnFromSelector,
      isWohnortReadOnly: isVnWohnortReadOnly,
      fullName: vnFromSelector
        ? `${vnFromSelector.vorname} ${vnFromSelector.nachname}`
        : "",
    },
    vps,
    isOneTimeEmailVisible: isOneTimeEmailVisible(
      ownProps.staticPersonalData.disableEwe,
      ownProps.inquiryType,
      vnFromSelector
    ),
  };
  return {
    mappedValues,
  };
};

// type inferrence does not work correctly due to a typings issue.
// see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/19989
// current workaround is not to type the Form component
// tslint:disable-next-line:no-any
export default (connect(mapStateToProps) as any)(Form);

export { formName };
