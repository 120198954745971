import * as React from 'react';
import { IMeta } from '../subcomponents/IMeta';
import PlzOrtField from '../subcomponents/PlzOrtField';
import StrasseHausnummerField from '../subcomponents/StrasseHausnummerField';

interface IAddressSectionProps {
    isWohnortReadOnly: boolean;
    isGWGRequired?: boolean;
    touch: (...field: string[]) => void;
    validateAddress?: boolean;

    isValidStreetVp?: boolean;
    setValidStreetVp?: (valid: boolean) => void;
    isValidHousenumberVp?: boolean;
    setValidHousenumberVp?: (valid: boolean) => void;
    isValidPostalCityVp?: boolean;

    isValidStreetVn?: boolean;
    setValidStreetVn?: (valid: boolean) => void;
    isValidHousenumberVn?: boolean;
    setValidHousenumberVn?: (valid: boolean) => void;
    isValidPostalCityVn?: boolean;
    setValidPostalCityVn?: (valid: boolean) => void;

    role: string;
}

enum dataComponentIdsSelectorEnum {
    street = 'strasse',
    houseNumber = "hausnummer",
    postalCode = "plz"
  };

interface IAddressErrorMessageProps {
    divRef: React.RefObject<HTMLDivElement>;
    selectorForElementToBeFocused:string;
}

const AddressErrorMessage: React.FC<IAddressErrorMessageProps> = ({ divRef,selectorForElementToBeFocused }) => {
    React.useEffect(() => {
        if (divRef.current) {
            divRef.current.scrollIntoView({ behavior: 'smooth' });
            //for focusing first field with error under divRef. Every input field has a unique data-component-id.So this attribute is used to get the element then focus it. 
            //ScrollintoView would happen in parallel. So it looks fine. dataComponentIdsEnum is hardcoded with possible values from web-ui/src/subcomponents/StrasseHausnummerField.tsx , web-ui/src/subcomponents/PlzOrtField.tsx
            if (selectorForElementToBeFocused !== "") {
                let firstFieldWithError = divRef.current?.querySelector(`input[data-component-id*=${selectorForElementToBeFocused}]`);
                (firstFieldWithError as HTMLElement)?.focus();
            }
        }
    }, [divRef,selectorForElementToBeFocused]);

    return (
        <div className="ee_form-row ee_form-row--wide">
            <div className="ee_form-row__descriptor ee_form-row__descriptor--wide">&nbsp;</div>
            <div style={{
                textAlign: 'left',
                margin: 'auto',
                color: '#BF1528'
            }} data-component-id="personal-data-address-corrected-message">
                Es können nur Adressen innerhalb Deutschlands akzeptiert werden
            </div>
        </div>
    );
};

const AdressSection: React.StatelessComponent<IAddressSectionProps & IMeta> = (props) => {
    const divRef = React.useRef<HTMLDivElement>(null);

    if(props.role === 'vp'){
        let selectorForElementToBeFocused = ""
        if (props.isValidStreetVp === false) {
            selectorForElementToBeFocused = dataComponentIdsSelectorEnum.street
        } else if (props.isValidHousenumberVp === false) {
            selectorForElementToBeFocused = dataComponentIdsSelectorEnum.houseNumber
        } else if (props.isValidPostalCityVp === false) {
            selectorForElementToBeFocused = dataComponentIdsSelectorEnum.postalCode
        } else {
            selectorForElementToBeFocused = ""
        }
    return (
        <div ref={divRef}>
            <StrasseHausnummerField
                meta={props.meta}
                validateAddress={props.validateAddress}
                isValidStreet={props.isValidStreetVp}
                setValidStreet={props.setValidStreetVp}
                isValidHousenumber={props.isValidHousenumberVp}
                setValidHousenumber={props.setValidHousenumberVp}
            />
            <PlzOrtField
                meta={props.meta}
                isReadOnly={props.isWohnortReadOnly}
                validateAddress={props.validateAddress}
                isValidPostalCity={props.isValidPostalCityVp}
            />
            {( props.isValidHousenumberVp === false || props.isValidStreetVp === false || props.isValidPostalCityVp === false) && <AddressErrorMessage divRef={divRef} selectorForElementToBeFocused={selectorForElementToBeFocused}/>}
          </div>
    );}
    else {
        let selectorForElementToBeFocused = ""
        if (props.isValidStreetVn === false) {
            selectorForElementToBeFocused = dataComponentIdsSelectorEnum.street
        } else if (props.isValidHousenumberVn === false) {
            selectorForElementToBeFocused = dataComponentIdsSelectorEnum.houseNumber
        } else if (props.isValidPostalCityVn === false) {
            selectorForElementToBeFocused = dataComponentIdsSelectorEnum.postalCode
        } else {
            selectorForElementToBeFocused = ""
        }
        return (
            <div ref={divRef}>
                <StrasseHausnummerField
                    meta={props.meta}
                    validateAddress={props.validateAddress}
                    isValidStreet={props.isValidStreetVn}
                    setValidStreet={props.setValidStreetVn}
                    isValidHousenumber={props.isValidHousenumberVn}
                    setValidHousenumber={props.setValidHousenumberVn}
                />
                <PlzOrtField
                    meta={props.meta}
                    isReadOnly={props.isWohnortReadOnly}
                    validateAddress={props.validateAddress}
                    isValidPostalCity={props.isValidPostalCityVn}
                    setValidPostalCity={props.setValidPostalCityVn}
                /> {( props.isValidHousenumberVn === false || props.isValidStreetVn === false || props.isValidPostalCityVn === false) && <AddressErrorMessage divRef={divRef} selectorForElementToBeFocused={selectorForElementToBeFocused}/>}
            </div>
        );
    }
};

export default AdressSection;
